.toolBarWrapper {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 50%;
  padding: 0.5rem;
  transform: translateX(-50%);
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #ccc;
  box-shadow: 0 0 0.5rem 0.5rem rgb(0 0 0 / 30%);
}

.toolWrapper {
  width: 4.5rem;
  height: 4.5rem;
}

.toolWrapperSelected,
.iconButton:hover > .toolWrapper {
  padding: 0.2rem;
}

.iconButton {
  margin: 0.4rem;
  border: 0;
  background: none;
}

.iconButtonSelected,
.iconButton:hover {
  border-radius: 0.8rem;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  cursor: pointer;
}
